import React, { useState, Suspense, } from 'react';
import { BrowserRouter, } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, responsiveFontSizes, } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import theme from './theme';
import AppRouter from './routes/AppRouter';
import UserContext from './context/UserContext';

const App = () => {
  const themeX = responsiveFontSizes(theme, { breakpoints: ["xs", "sm", "md", "lg", "xl"] });

  const [user, setUser] = useState({
    isLoggedIn: false,
    isReloadImage: false,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeX}>
        <Suspense fallback={<LinearProgress />}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <UserContext.Provider value={[user, setUser]}>
              <BrowserRouter>
                <AppRouter />
              </BrowserRouter>
            </UserContext.Provider>
          </LocalizationProvider>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;