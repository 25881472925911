import React, { useState, useEffect, lazy, Suspense, } from 'react';
import { Switch, Redirect, Route, useLocation, } from 'react-router-dom';
import { getEmployeeNavItems, getNavItems, getNonNavItems, } from './navs';
import LinearProgress from '@mui/material/LinearProgress';
import UserContext from '../context/UserContext';
import jsAPI from '../helpers/jsAPI';
import common from '../helpers/common';
import PublicRouter from './PublicRouter';

const MainLayout = lazy(() => import('../layouts/Main'));
const NotFoundView = lazy(() => import('../views/NotFoundView'));

const NavigationRouter = (props) => {
  const { client_code, accessKeys, user_type, } = props;
  const nonNavItems = [...getNonNavItems()];
  const navItems = [...(user_type === "admin" ? getNavItems() : getEmployeeNavItems())];

  let origNavItems = [...navItems];
  let routes = [];

  const processRoutes = (route) => {
    // check first if has access in module
    let hasAccess = true;
    if (route.accessKey) {
      hasAccess = route.accessKey === "always_show" || Boolean(common.findInNestedArray(accessKeys, "access_key", null, route.accessKey));
    }
    else if (!route.children && !route.accessKey) {
      hasAccess = false;
    }

    if (hasAccess === false) {
      return;
    }
    
    if (route.path) {
      routes.push(route);
    }
    else if (route.children && route.children.length > 0) {
      route.children.forEach(child => {
        processRoutes(child);
      })
    }
  }

  origNavItems.forEach(item => {
    processRoutes(item);
  });

  routes = [...nonNavItems, ...routes];

  const getLandingPage = (userType, accessKeys) => {
    let route = `/${client_code}/app/`;

    // We don't do anything special with employee,
    // so we use it as a guard clause
    if ( user_type !== "admin" ){
      route += `reports/employee_productivity_dashboard`;
      return route;
    }

    if (Boolean(accessKeys.find((el) => el.access_key === "CompanyProductivityDashboard"))){
      route += `reports/productivity_dashboard`;
    }
    else {
      route += `default`;
    }

    return route;
  }

  return (
    <Switch>
      {routes.map((route, index) => {
        let path = route.path;
        let updatedPath = path;

        if (Array.isArray(route.path)) {
          path = [...route.path];
          updatedPath = [];

          path.forEach((p) => {
            updatedPath = [...updatedPath, p === '/select_user_type' ? p : `/:client_code${p}`];
          });
        }
        else {
          updatedPath = updatedPath === '/select_user_type' ? updatedPath : `/:client_code${updatedPath}`;
        }

        return (
          <Route
            key={index}
            exact
            path={updatedPath}
            component={route.component}
          />
        );
      })}
      <Redirect
        exact
        from={`/${client_code}/app`}
        to={getLandingPage(user_type, accessKeys)}
      />
      <Route
        path="*"
        component={() => <NotFoundView hideHeader hideFooter />}
      />
    </Switch>
  )
}

const AppRouter = () => {
  const location = useLocation();

  const [user, setUser] = React.useContext(UserContext);

  const [isApp, setIsApp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accessKeys, setAccessKeys] = useState([]);
  const [checkedSession, setCheckedSession] = useState(false);
  const [isUserTypePath, setIsUserTypePath] = useState(false);

  useEffect(() => {
    let _isApp = location.pathname === '/select_user_type'  || location.pathname.split("/")[2] === "app";
    setIsApp(_isApp);
  }, [location]);

  useEffect(() => {
    try {
      if (isApp === true) {
        setLoading(true);
  
        const getDetails = async () => {
          const responseJSON = await jsAPI.request("/user_security_module/get_user_modules", {
            method: "POST"
          });

          setAccessKeys([...responseJSON.data, {access_key: "always_show"}]);
  
          setUser(val => {
            return {
              ...val,
              accessKeys: [...responseJSON.data, {access_key: "always_show"}],
              ...responseJSON.user[0],
            }
          });
        }
        
        const checkSession = async () => {
          const responseJSON = await jsAPI.request("/check_session");
          const isLoggedIn = responseJSON.success;
          
          if (responseJSON?.is_select_user === true && isUserTypePath){
            window.location.replace(`/select_user_type`)
          }

          
          if (!responseJSON?.is_select_user && isUserTypePath){
            window.location.replace(`/`)
          }
      
          setUser(val => {
            return {
              ...val,
              isLoggedIn,
              user_type: responseJSON.user_type
            }
          });
  
          if (isLoggedIn === true) {
            await getDetails();
          }
  
          setCheckedSession(true);
          setLoading(false);
        }
  
        checkSession();
      }
      else {
        setLoading(false);
      }
    }
    catch (err) {
    }
  }, [isApp, setUser, isUserTypePath]);

  useEffect(() => {
    if (location.pathname === `/select_user_type`){
      setIsUserTypePath(true)
    }
    else{
      setIsUserTypePath(false)
    }
  },[location.pathname])

  useEffect(() => {
    if (user.isLoggedIn === false && checkedSession === true) {
      window.location.href = "/login";
    }
  }, [user.isLoggedIn, checkedSession, user.client_code]);

  return (
    <>
      {loading !== true ? (
        <>
          {isApp === true ? (
            <>
              {user.isLoggedIn === true && (
                <MainLayout>
                  <Suspense fallback={<LinearProgress />}>
                    <NavigationRouter
                      client_code={user.client_code}
                      accessKeys={accessKeys}
                      user_type={user.user_type}
                    />
                  </Suspense>
                </MainLayout>
              )}
            </>
          ) : (
            <PublicRouter />
          )}
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

export default AppRouter;
