let emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/ //eslint-disable-line

const isEmailValid = (email) => {
  if (!email)
    return false

  if (email.length > 254)
    return false

  let valid = emailRegex.test(email)
  if (!valid)
    return false

  // Further checking of some things regex can't handle
  let parts = email.split('@')
  if (parts[0].length > 64)
    return false

  let domainParts = parts[1].split('.')
  if (domainParts.some(function(part) { return part.length > 63 }))
    return false

  return true
}

const findInNestedArray = (arr, key, nestedKey, valueToFind) => {
  return arr.reduce((a, item) => {
    if (a) {
      return a;
    }
    if (item[key] === valueToFind) {
      return item;
    }
    if (nestedKey && item[nestedKey]) {
      return findInNestedArray(item[nestedKey], key, nestedKey, valueToFind);
    }

    return null;
  }, null);
}

const getParentByChild = (arr, key, nestedKey, valueToFind) => {
  return arr.reduce((a, item) => {
    if (a) {
      return a;
    }
    if (item[key] === valueToFind) {
      return a;
    }
    if (nestedKey && item[nestedKey]) {
      return getParentByChild(item[nestedKey], key, nestedKey, valueToFind);
    }

    return null;
  }, null);
}

const removeObjectInNestedArray = (arr, key, nestedKey, valueToFind) => {
  let arrToProcess = JSON.parse(JSON.stringify(arr));

  const recurse = (_arr) => {
    return _arr.reduce((a, item, index, aa) => {
      if (a) {
        return a;
      }
      if (item[key] === valueToFind) {
        aa.splice(index, 1);
        return recurse(aa);
      }
      if (nestedKey && item[nestedKey]) {
        return recurse(item[nestedKey]);
      }
  
      return null;
    }, null);
  }

  recurse(arrToProcess);
  return arrToProcess;
}

const isAccessible = (accessKeys, accessKey) => {
  let result = false;

  for (let a of accessKeys) {
    if (a.access_key === accessKey) {
      result = true;
      break;
    }
  }

  return result;
}

const obj = {
  findInNestedArray,
  removeObjectInNestedArray,
  isAccessible,
  isEmailValid,
  getParentByChild,
}

export default obj;