import palette from './palette';

const obj = {
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '2.1875rem',
    // letterSpacing: '-0.24px',
    // lineHeight: '40'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.8125rem',
    // letterSpacing: '-0.24px',
    // lineHeight: '32'
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.5rem',
    // letterSpacing: '-0.06px',
    // lineHeight: '28'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.25rem',
    // letterSpacing: '-0.06px',
    // lineHeight: '24'
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1rem',
    // letterSpacing: '-0.05px',
    // lineHeight: '20'
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '0.875rem',
    // letterSpacing: '-0.05px',
    // lineHeight: '20'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '1rem',
    // letterSpacing: '-0.05px',
    // lineHeight: '25'
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '0.875rem',
    // letterSpacing: '-0.05px',
    // lineHeight: '21'
  },
  body1: {
    color: palette.text.primary,
    fontSize: '0.875rem',
    // letterSpacing: '-0.05px',
    // lineHeight: '21'
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '0.75rem',
    // letterSpacing: '-0.04px',
    // lineHeight: '18'
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.875rem'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '0.875rem',
    // letterSpacing: '0.33px',
    // lineHeight: '13px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '0.6875rem',
    fontWeight: 500,
    // letterSpacing: '0.33px',
    // lineHeight: '13',
    textTransform: 'uppercase'
  },
  listText: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '0.875rem',
    // letterSpacing: '-0.05px',
    // lineHeight: '21'
  },
  fontWeightMedium: 600
};

export default obj;
