import React, { lazy, } from 'react';
import { Switch, Route, } from 'react-router-dom';

const LoginForm = lazy(() => import('../forms/LoginForm'));
const AccountView = lazy(() => import('../views/AccountView'));
const NotFoundView = lazy(() => import('../views/NotFoundView'));
const NoUserFoundView = lazy(() => import('../views/NoUserFoundView'));
// const Download = lazy(() => import('../views/DownloadView'));

const PublicRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={["/pub/change_pass/:params?", "/accounts/activate/:token", "/accounts/forgot_password", "/accounts/forgot_password", "/accounts/signup", "/:client_code/accounts/signup"]}
        component={AccountView}
      />
      <Route
        path="/sso_error_page"
        component={NoUserFoundView}
      />
      <Route
        exact
        path={["/", "/login"]}
        component={LoginForm}
      />
      {/* <Route */}
      {/*   exact */}
      {/*   path={["/downloads/desktop_app"]} */}
      {/*   component={Download} */}
      {/* /> */}
      {/* <Route
        exact
        path={["/login", "/:client_code", "/:client_code/login"]}
        component={LoginForm}
      /> */}
      <Route
        path="*"
        component={NotFoundView}
      />
    </Switch>
  );
}

export default PublicRouter;
