import { colors } from '@mui/material';

const white = "#FFFFFF";
const black = "#000000";
const red = "#D53238";

const obj = {
  black,
  white,
  red,
  online: {
    main: "green",
    contrastText: "white",
  },
  idle: {
    main: "yellow",
    contrastText: "black",
  },
  offline: {
    main: "red",
    contrastText: "white",
  },
  break: {
    main: colors.lightGreen[100],
    contrastText: "black",
  },
  overbreak: {
    main: colors.orange[200],
    contrastText: "black",
  },
  primary: {
    contrastText: white,
    light: "#d7e2e8",
    main: "#376C8B",
    dark: "#274c61"
  },
  secondary: {
    contrastText: white,
    light: "#A9C95A",
    main: "#618109",
    dark: "#3B5707",
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
    lighter: colors.green[100]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
    lighter: colors.red[100]
  },
  text: {
    primary: "#3d3d3d",
    secondary: "#616161",
    link: colors.blue[600]
  },
  background: {
    default: '#3D3D3D',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};

export default obj;
